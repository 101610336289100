.App.light {
  --background: #0B2942;
  --paper: #EeEeEe;
  --button: #EeEeEe;
  --input: rgba(255, 255, 255, 0.5);
  --option: #F7F7F7;
  --foreground: rgba(0, 0, 0, 0.9);
  --icon: rgba(0, 0, 0, 0.7);
  --outline: rgba(0, 0, 0, 0.2);
  --inset-top: #AAA !important;
  --inset-bottom: #CCC !important;
  --outset-top: #CCC !important;
  --outset-bottom: #AAA !important;
  --scrollbar-background-1: rgba(0,0,0,0.06);
  --scrollbar-background-2: rgba(0,0,0,0.01);
  --scrollbar-foreground-1: rgba(0,0,0,0.01);
  --scrollbar-foreground-2: rgba(0,0,0,0.03);
}