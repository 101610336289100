:root {
  --shadow-24: 0rem 0.1rem 2.4rem 0.1rem rgba(0, 0, 0, 0.8) !important;
  --shadow-16: 0rem 0.1rem 1.6rem 0.1rem rgba(0, 0, 0, 0.7) !important;
  --shadow-12: 0rem 0.1rem 1.2rem 0.1rem rgba(0, 0, 0, 0.6) !important;
  --shadow-08: 0rem 0.1rem 0.8rem 0.1rem rgba(0, 0, 0, 0.5) !important;
  --shadow-06: 0rem 0.1rem 0.6rem 0.1rem rgba(0, 0, 0, 0.4) !important;
  --shadow-04: 0rem 0.1rem 0.4rem 0.1rem rgba(0, 0, 0, 0.3) !important;
  --shadow-02: 0rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2) !important;
  --shadow-01: 0rem 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1) !important;
}

*,
*::before,
*::after {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0;
  color: var(--foreground);
}

/** * /
*:hover {
  outline: 1px dashed yellowgreen;
}
/** */

html,
body,
#root,
.App {
  min-width: 320px;
  width: 100%;
  height: 100%;
  display: inline-block;
}

html {
  font-size: 16px;
}

/** Lower than medium screens */
@media only screen and (max-width: 768px) {
  html {
    font-size: 15px !important;
  }
}

/** Lower than small screens */
@media only screen and (max-width: 640px) {
  html {
    font-size: 14px !important;
  }
}

/** Lower than extra small screens */
@media only screen and (max-width: 480px) {
  html {
    font-size: 13px !important;
  }
}

pre,
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre {
  margin: 0;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

hr {
  margin: 0.25rem 0;
  border: 0;
  border-bottom: 1px solid var(--outline);
}

textarea {
  padding: 0.5rem;
  min-height: 3.8rem;
}

input[type="text"],
input[type="number"],
input[type="password"] {
  padding: 0.25rem 0.5rem;
}

select,
textarea,
select,
.input,
input[type="date"],
input[type="time"],
input[type="datetime"],
input[type="text"],
input[type="number"],
input[type="password"] {
  background-color: var(--input);
  color: var(--foreground);
  border: 1px solid var(--outline);
  border-top: 1px solid var(--inset-top);
  border-left: 1px solid var(--inset-top);
  border-right: 1px solid var(--inset-bottom);
  border-bottom: 1px solid var(--inset-bottom);
  border-radius: 4px;
  outline: 0;
  padding: 0.25rem 0.5rem;
  /** * /
  box-shadow: inset var(--shadow-01);
  /** */
}

select option {
  background-color: var(--option) !important;
  color: var(--foreground) !important;
}

textarea:hover,
input:hover,
.input:hover,
select:hover,
textarea:focus,
input:focus,
.input:focus,
select:focus {
  border: 1px solid royalblue;
}

button {
  background-color: var(--button);
  border: none;
  padding: 0.5rem 1rem;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  border-top: 1px solid var(--outset-top);
  border-left: 1px solid var(--outset-top);
  border-right: 1px solid var(--outset-bottom);
  border-bottom: 1px solid var(--outset-bottom);
  border-radius: 4px;
  /** TRUNCATE start >>> */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /** <<< end */
  /* Safari */
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  box-shadow: none;
  cursor: pointer;
  text-decoration: none;
  border-top: 1px solid var(--outset-top);
  border-left: 1px solid var(--outset-top);
  border-right: 1px solid var(--outset-bottom);
  border-bottom: 1px solid var(--outset-bottom);
  /* Safari */
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}

.button:hover,
button:hover {
  box-shadow: var(--shadow-02);
  transform: translate(-1px, -1px);
  font-weight: 700;
}

.button:active,
button:active {
  box-shadow: var(--shadow-01);
  transform: translate(-0px, -0px);
}

.button:focus,
button:focus {
  outline: 0;
}

button:after,
.button:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, currentColor 10%, transparent 10.01%);
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 1s;
}

button:active:after,
.button:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

.µ-bg {
  background-color: var(--background);
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}

.µ-bg-image {
  content: "";
  background-image: url(../images/bg_00.png);
  background-repeat: repeat;
  height: 100%;
  width: 100%;
  opacity: 0.05;
}

.µ-paper {
  background-color: var(--paper);
  border: 1px solid var(--outline);
  border-radius: 4px;
  padding: 0.5rem;
  max-width: calc(100% - 2rem);
  width: 800px;
  max-height: calc(100% - 5.2rem);
  margin: 1rem auto;
  box-shadow: var(--shadow-04);
}

.µ-button-icon {
  width: 2rem;
  height: 2rem;
  color: var(--icon);
  font-size: 1.2rem;
  border: 0px solid gray;
  border-radius: 50%;
  outline: 0;
  padding: 0;
  cursor: pointer;
  border: 1px solid var(--outline);
  box-shadow: none;
  align-self: center;
  /** * /
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-content: center;
  justify-items: center;
  /** */
}

.µ-button-icon * {
  display: flex;
  align-self: center;
  margin: auto;
}

.µ-button-icon>img {
  filter: grayscale(100%);
  width: 1.2rem;
  height: 1.2rem;
  object-fit: cover;
  border-radius: 50%;
}

.µ-button-icon:hover {
  font-weight: normal;
}

.capitalize {
  text-transform: capitalize;
}

.circled {
  border-radius: 50%;
}

.col {
  display: flex;
  flex-direction: column;
}

.c-gray {
  color: gray;
}

.c-green {
  color: green;
}

.c-lime {
  color: lime;
}

.c-red {
  color: red;
}

.c-royal-blue {
  color: royalblue;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.f-1 {
  font-size: 1rem;
}

.f-16 {
  font-size: 16px;
}

.f-italic {
  font-style: italic;
}

.f-bold {
  font-weight: bold;
}

.justify-right {
  justify-items: right;
  justify-content: flex-end;
}

.justify-start {
  justify-items: left;
}

.justify-center {
  justify-items: center;
}

.justify-content-center {
  justify-content: center;
}

.d-flex {
  display: flex;
}

.filter-gray {
  filter: grayscale(100%);
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
}

.fixed-top {
  position: fixed;
  top: 0;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.f-right {
  float: right;
}

.sh-32 {
  height: 32px;
}

.h-min50 {
  min-height: 50%;
}

.h-auto {
  height: auto;
}

.h-100 {
  height: 100%;
}

.w-50 {
  width: 50%;
}


.w-100 {
  width: 100%;
}

.max-w-90 {
  max-width: 90%;
}

.mr-auto {
  margin-right: auto;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.o-05{
  opacity: 0.5;
}

.outlined {
  border: 1px solid var(--outline);
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pr-3 {
  padding-right: 2rem;
}

.px-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.pointer {
  cursor: pointer;
}

.rounded {
  border-radius: 4px;
}

.scroll-y {
  overflow-y: auto;
}

.scroll-x {
  overflow-x: auto;
}

.s-m-input {
  min-width: 140px;
  height: 32px;
}

.s-m-button {
  width: 140px;
  height: 32px;
}

.s-2 {
  width: 1.2rem;
  height: 1.2rem;
}

.s-16 {
  width: 16px;
  height: 16px;
}

.s-32 {
  width: 32px;
  height: 32px;
}

.space-between {
  place-content: space-between;
}

/** Scrollbar start >>> */

* {
  scrollbar-color: var(--scrollbar-foreground-1);
}

*::-webkit-scrollbar {
  width: 0.9rem;
  height: 0.9rem;
}

*::-webkit-scrollbar-track {
  background: linear-gradient(90deg,
      var(--scrollbar-background-1) 0%,
      var(--scrollbar-background-2));
  border-radius: 1rem;
  /** * /
  border: 1px solid var(--outline);
  /** */
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg,
      var(--scrollbar-foreground-1),
      var(--scrollbar-foreground-2));
  border-radius: 1rem;
  border: 1px solid var(--outline);
  border-top: 1px solid var(--outset-top);
  border-left: 1px solid var(--outset-top);
  border-right: 1px solid var(--outset-bottom);
  border-bottom: 1px solid var(--outset-bottom);
}

/** <<< end Scrollbar */