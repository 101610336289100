.App.dark {
  --background: #011627;
  --paper: rgba(18, 33, 54, 0.75);
  --button: #111F33;
  --input: rgba(255, 255, 255, 0.05);
  --option: #1E2C40;
  --foreground: rgba(255, 255, 255, 0.9);
  --icon: rgba(255, 255, 255, 0.7);
  --outline: rgba(255, 255, 255, 0.2);
  --inset-top: #444 !important;
  --inset-bottom: #777 !important;
  --outset-top: #777 !important;
  --outset-bottom: #444 !important;
  --scrollbar-background-1: rgba(0,0,0,0.2);
  --scrollbar-background-2: rgba(0,0,0,0.1);
  --scrollbar-foreground-1: rgba(0,0,0,0.1);
  --scrollbar-foreground-2: rgba(0,0,0,0.2);
}

.App.dark ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.App.dark .µ-button-icon>img {
  filter: grayscale(100%) invert(1);
}